/* website: 3249-landroverbrossard1
 * created at 2020-11-30 15:33 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/land-rover.scss";
@import "../templates/groups/parkavenue.scss";
@import "../utils/icons.scss";

.widget-sr{
    &.dealer__land-rover-brossard{
        .is-bold{
            font-weight: unset !important;
        }
        .vehicle-cta-btn{
            background:  #005A2B !important;
            font-weight: unset !important;
        }
        .TransactionActionDetails--wrapper .sr-button-outline-1{
            padding: 15px 14px !important;
            text-transform: uppercase !important;
        }
        .sr-button-1,
        .sr-button-2,
        .sr-button-outline-1,
        .sr-button-outline-2{
            box-shadow: none!important;
        }
        .sr-paymentCalculator-background,
        .paymentCalculator__title--note.is-light,
        .paymentCalculator__payment--details.is-light,
        .paymentCalculator__rebates--term.is-light,
        .paymentCalculator__rebates--term.is-light .icon-promo2.is-util,
        #calculator-toggle-button.sr-button-1{
            color: #ffffff;
        }
        #calculator-toggle-button.sr-button-1{
            background: #005A2B;
        }
        .paymentCalculator__payment{
            .is-util,
            .is-util.is-bold{
                color: #005A2B;
            }
        }
        .paymentCalculator__interests{
            .paymentCalculator__interests--amount{
                color: #005A2B;
            }
        }
        .sr-paymentCalculator-background{
            background-color: #999999;
        }
    }
  }